@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@font-face {
    font-family: 'multi-display-thin';
    src: url(/static/media/multidisplay-thin-webfont.43fd895c.woff2);
    src: url(/static/media/multidisplay-thin-webfont.c7c7aa4e.woff);
}

@font-face {
    font-family: 'multi-display-regular';
    src: url(/static/media/multidisplay-regular-webfont.45e4dfbf.woff2);
    src: url(/static/media/multidisplay-regular-webfont.59f017a0.woff);
}

@font-face {
    font-family: 'multi-display-medium';
    src: url(/static/media/multidisplay-medium-webfont.e07a4127.woff2);
    src: url(/static/media/multidisplay-medium-webfont.45b83f61.woff);
}

@font-face {
    font-family: 'multi-display-bold';
    src: url(/static/media/multidisplay-bold-webfont.853173c3.woff2);
    src: url(/static/media/multidisplay-bold-webfont.5f367e75.woff);
}

@font-face {
    font-family: 'multi-display-extrabold';
    src: url(/static/media/multidisplay-extrabold-webfont.6c522ad7.woff2);
    src: url(/static/media/multidisplay-extrabold-webfont.242eb35e.woff);
}

@font-face {
    font-family: 'nobel-light';
    src: url(/static/media/nobel_book-webfont.c4e1cae5.woff2);
    src: url(/static/media/nobel_book-webfont.5066c6eb.woff);
}

@font-face {
    font-family: 'nobel-regular';
    src: url(/static/media/nobel-regular-webfont.948adc77.woff2);
    src: url(/static/media/nobel-regular-webfont.9aa938e2.woff);
}

@font-face {
    font-family: 'nobel-bold';
    src: url(/static/media/nobel-bold-webfont.2d275f05.woff2);
    src: url(/static/media/nobel-bold-webfont.5131764f.woff);
}

.systematicTradingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto auto;
    position: relative;
    background-color: #E6E7FF;

    --home-bg: #E6E7FF;
    --home-primary-text: 18px;
    --home-secondary-text: 12px;
    --margin-vertical: 0;
    --margin-horizontal: 50px;
    --home-bold: 800;
    --home-semi-bold: 600;
    --home-medium: 500;
    --home-regular: 400;
    --home-small: 12px;
    --home-text: 16px;
    --home-text-22: 22px;
    --home-text-15: 15px;

    --home-large: 34px;
    --home-large-1: 40px;
    --home-xlarge: 48px;

    --border-color: #00000012;
    --home-violet-light: #F3F3FF;
    --home-gray-light: #FAFBFE;
    --home-green: #07e221;
    --home-blue: #0013f4;
    --home-blue-300: #3e72ff;
    --home-text-primary: #000651;
    --home-text-secondary: #636a97;
    --text-primary: #000000;
    --text-grey: #ffffff60;
    --text-grey-dark: rgb(0 0 0 / 50%);
    --text-grey-1: #9da0ad;
    --home-voilet: #3e24e8;
    --home-red: #eb1d54;
    --home-green: #3cbb00;

    --home-inputBorder: #bfcad9;
    --home-tabsBg: #f6f8fb;

    --home-anim-ease: cubic-bezier(0, 0.62, 0.24, 1.04);
}

.homeContainerWrapper {
    width: 100%;
    background-color: var(--home-bg);
}

.homeContainerMain {
    max-width: 1600px;
    width: 100%;
    margin: auto auto;
    background-color: var(--home-bg);
}

.homeBackgroundGradientLeft {
    position: absolute;
    height: 100%;
    width: 2%;
    left: 0;
    top: 0;
    z-index: 3;
    background-color: var(--home-bg);
    opacity: 0.9;
    -webkit-filter: blur(20px);
            filter: blur(20px)
}

.homeBackgroundGradientRight {
    position: absolute;
    height: 100%;
    width: 2%;
    right: 0;
    top: 0;
    z-index: 3;
    background-color: var(--home-bg);
    opacity: 1;
    -webkit-filter: blur(25px);
            filter: blur(25px);
}

.systematicTradingContainer p {
    font-weight: var(--home-regular);
}

.systematicTradingContainer h1 {
    color: var(--home-text-primary);
}

.systematicTradingContainer h5 {
    line-height: 1.6;
    color: var(--text-grey-dark);
    font-weight: var(--home-regular);
    font-size: var(--home-text-15);
}

.bannerFeatureContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* padding-right: 6%; */
    /* gap: 2%; */
}

.videoContainer {
    width: 58%
}

.bannerCenterContent {
    width: 50%;
    margin-left: -3%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.startedTitle {
    font-size: 28px;
    font-family: multi-display-bold;
    color: var(--home-text-primary);
}

.startedSubTitle {
    font-family: nobel-light;
    font-weight: 100;
    font-size: 16px;
    color: var(--home-text-secondary);
}

.getStartedLabel {
    font-size: 16px;
    font-family: multi-display-medium;
}

.getStartedBtn {
    box-shadow: 2px 4px 30px #586DF780;
    padding: 15px 45px;
    margin: 10px 0;
}

.getStartedBtn:hover {
    box-shadow: 2px 4px 30px #344ce892;
    background-color: #344ce8 !important;
}

.brokerList {
    display: flex;
    flex-direction: row;
    align-content: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
    border-radius: 16px;
    background-color: var(--home-violet-light);
}

.bannerBrokerImg {
    align-self: center;
    margin: 5px;
    width: 50px;
}

.planContainerWrapper {
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.planContainer {
    width: 100%;
    max-width: 1600px;
    margin: auto auto;
    background-color: white;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    grid-gap: 50px;
    gap: 50px
}

.planBannerDesignRight {
    position: absolute;
    width: 30%;
    top: 50;
    right: 0;
    -webkit-transform: translateX(65%);
            transform: translateX(65%);
}

.planBannerDesignLeft {
    position: absolute;
    width: 30%;
    bottom: 0;
    left: 0;
    -webkit-transform: translate(-65%, 50%);
            transform: translate(-65%, 50%);
}

.whyStreakTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--home-gray-light);
    border-radius: 24px;
    padding: 3% 5%;
    width: 65%;
}

.WSTableHeader {
    width: 100%;
}

.WSTableRow {
    width: 100%;
    margin-bottom: -2%;
}

.ReadMore {
    color: #445DFF;
    font-weight: 800;
    cursor: pointer;
    font-family: multi-display-medium;
    font-size: 16px;
}

.infoBannerWrap {
    width: 100%;
    padding: 5%;
    background-color: white;
}

.infoBannerContainer {
    width: 100%;
    padding: 0% 5%;
    grid-gap: 5%;
    gap: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.infoBannerLeft {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoBannerLeftContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.infoBannerRight {
    width: 50%;
}

.infoBannerBannerContainer {
    margin: var(--margin-horizontal) 0;
    display: flex;
    justify-content: space-between;
}

.infoBannerFeaturesTitle {
    font-size: 44px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    color: var(--text-grey-1);
    font-weight: var(--home-medium);
}

.infoBannerFeaturesSubTitle {
    font-size: 24px;
}

.infoBannerLeft {
    flex: 0.5 1;
}

.infoBannerLeftContent button {
    display: block;
}

.infoBannerLeftLabel {
    color: #000651;
    font-size: 18px;
    font-weight: 700;
}

.featureButtons {
    padding: 5px 10px;
}

.featureButtons>div:hover p,
.featureButtons>div:hover .icon {
    color: #445DFF !important;
}

.featuresDesc {
    font-size: 18px;
}

.featureButtonsActive {
    padding: 5px 10px;
    background-color: var(--home-violet-light);
    border-radius: 15px;
}

.learnMore {
    color: #445DFF;
    font-weight: 800;
    margin: 10px 30px;
    cursor: pointer;
    font-family: multi-display-medium;
}

.infoBannerRight {
    flex: 0.5 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-height: 300px;
    margin-bottom: 18px;
}

.infoBannerRightContent {
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-duration: 0.45s;
            animation-duration: 0.45s;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    opacity: 0;
    width: 100%;
    margin: 10px 0;
}

.infoBannerRightText {
    text-align: center;
    margin-top: 30px;
    font-size: var(--home-text-22) !important;
    color: var(--text-grey-1);
    font-weight: var(--home-medium);
    white-space: pre-line;
}

.infoBannerRightImg {
    width: 110px;
}

.infoBannerRightButtons {
    position: absolute;
    display: flex;
    align-items: center !important;
    bottom: -5%;
}

.infoBannerRightButtons input {
    margin: 0 8px;
}

.activeButton {
    width: 10px;
    height: 10px;
    background: #0013f4 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    cursor: pointer;
}

.inactiveButton {
    width: 10px;
    height: 10px;
    background: rgb(215, 215, 215) 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    cursor: pointer;
}

.infoBannerRightBgImg {
    width: 565px;
    height: 1100px !important;
    opacity: 0.85;
    position: absolute;
    right: -150px !important;
    top: 2250px;
}

.scrollFromUp {
    -webkit-animation-name: scrollUp;
            animation-name: scrollUp;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}

.scrollFromDown {
    -webkit-animation-name: scrollDown;
            animation-name: scrollDown;
    animation-direction: reverse;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}

.scrollToUp {
    -webkit-animation-name: normal;
            animation-name: normal;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

.scrollToDown {
    -webkit-animation-name: scrollDown;
            animation-name: scrollDown;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

.StatisticPageWrapper {
    width: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.StatisticWrapperContainer {
    width: 100%;
    max-width: 1600px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statBannerContainer {
    width: 70%;
    min-width: 600px;
    margin-bottom: 10%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #161932 0% 0% no-repeat padding-box;
    border-radius: 80px;
}

.statBannerCenterContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.statsGetStartedBtn {
    padding: 20px 60px;
    border: 3px solid #3746A9;
    border-radius: 50px !important;
    margin: 5% 0;
}

.statGetStartedLabel {
    font-size: 24px !important;
    font-family: multi-display-medium;
}

.statsGetStartedBtn:hover {
    background-color: #9543ff !important;
    border: 3px solid #6205dd;
    box-shadow: 2px 4px 30px #9543ff7d;
}

.statsContainer {
    width: 85%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 50px;
    gap: 50px;
    padding-bottom: 20px;
}

.statItem {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #ffffff;
    padding: 20px;
    background: #44497240 0% 0% no-repeat padding-box;
    box-shadow: 2px 27px 99px #A397F61A;
    border-radius: 20px;
    margin: 0 10px;
}

.flagIcon {
    width: 50px;
}

.testimonialWrapper {
    width: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonialFeaturesContainer {
    background-color: white;
    width: 100%;
    max-width: 1600px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
}

.testimonialContainer {
    width: 100% !important;
    min-height: 303px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    opacity: 1;
    scroll-snap-align: center;
    z-index: 3;
}

.styledSlider {
    display: grid !important;
    width: 150%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 -25%;
    grid-auto-flow: column;
    grid-gap: 3%;
    display: flex;
    overflow: scroll;
    grid-column-gap: 5%;
    -webkit-column-gap: 5%;
            column-gap: 5%;
    scroll-behavior: smooth;
    grid-auto-columns: 30%;
    overflow-x: hidden;
    -ms-scroll-snap-type: x proximity;
        scroll-snap-type: x proximity;
}

.rollToRight {
    -webkit-animation: .5s rollright normal;
            animation: .5s rollright normal;
}

.rollToLeft {
    -webkit-animation: .5s rollleft normal;
            animation: .5s rollleft normal;
}

.styledSlickSlider {
    display: flex;
}

.styledSlider::-webkit-scrollbar {
    display: none;
}

.comment {
    text-align: center;
    font-family: nobel-light;
}

.activeSlider {
    width: 12px;
    height: 12px;
    background: #fff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    cursor: pointer;
}

.unactiveSlider {
    width: 8px;
    height: 8px;
    background: #000 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    cursor: pointer;
}

.left-arrow {
    position: absolute;
    top: 40%;
    left: 25%;
    z-index: 5;
    /* font-size: 15px; */
    width: 60px;
    height: 60px;
    color: #08f;
    box-shadow: 2px 4px 24px #0005240D;
    border-radius: 50%;
    background-color: #fff;
    /* padding: 22px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-arrow>img {
    width: 20px;
}

.right-arrow>img {
    width: 20px;
}

.right-arrow {
    position: absolute;
    top: 40%;
    right: 25%;
    z-index: 5;
    font-size: 15px;
    width: 60px;
    height: 60px;
    color: #08f;
    box-shadow: 2px 4px 24px #0005240D;
    border-radius: 50%;
    background-color: #fff;
    /* padding: 22px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonialSubContainer {
    margin: 0px 20px;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85%;
    width: 70%;
}

.getStartedContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1f1ff;
    padding-top: 80px;
    grid-gap: 20px;
    gap: 20px;
}

.getStartedLabelFooter {
    font-size: 22px !important;
    font-family: multi-display-medium;
}

.getStartedBtFooter {
    padding: 30px 60px;
    margin: 20px;
    border-radius: 68px !important;
}

.getStartedBtFooter:hover {
    background-color: #344ce8 !important;
}

.sysFooterWrapper {
    background-color: #22242E;
    width: 100%;
    margin-bottom: 0;
    z-index: 3;
}

.sysFooter p {
    color: white;
}

.sysFooterBottom {
    margin-top: var(--margin-horizontal);
}

.streakLogo {
    height: 80px;
    display: block;
    margin-bottom: 20px;
}

.streakLogoTm {
    height: 24px;
    display: block;
    margin-bottom: 10px;
}

.streakWhiteLogo {
    height: 20px;
    display: block;
    margin-bottom: 20px;
}

.sysFooterBottomContent {
    display: flex;
}

.sysFooterBottomCol {
    flex: 1 1;
    display: inline-flex;
    flex-direction: column;
}

.sysFooterBottomColTitle {
    padding: 10px 0;
    color: white !important;
    font-size: 18px !important;
}

.sysFooterlink {
    display: inline-block;
    padding: 8px 0;
    font-size: 14px;
    color: var(--text-grey);
}

.sysFooterlinkFixed {
    max-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sysFooterlinkFixed img {
    width: 12px;
}

.sysFooterFb img {
    width: 9px !important;
}

.sysFooterTelegram img {
    width: 15px;
}

.sysFooterSocialContainer {
    width: 180px;
    display: inline-flex;
    flex-direction: column;
}

.copyright {
    margin: var(--margin-horizontal) 0 20px;
    padding: 20px 0;
}

.copyright h5 {
    color: var(--text-grey) !important;
}

@-webkit-keyframes scrollUp {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -220px, 0px);
                transform: translate3d(0, -220px, 0px);
    }
}

@keyframes scrollUp {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -220px, 0px);
                transform: translate3d(0, -220px, 0px);
    }
}

@-webkit-keyframes scrollDown {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
        /* transform: translateY(0) scale3d(1.4, 1.4, 1); */
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0px, 220px, 0px);
                transform: translate3d(0px, 220px, 0px);
        /* transform: translateY(80px) scale3d(0.4, 0.4, 0); */
    }
}

@keyframes scrollDown {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
        /* transform: translateY(0) scale3d(1.4, 1.4, 1); */
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0px, 220px, 0px);
                transform: translate3d(0px, 220px, 0px);
        /* transform: translateY(80px) scale3d(0.4, 0.4, 0); */
    }
}

@-webkit-keyframes scrollLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-220px, 0, 0px);
                transform: translate3d(-220px, 0, 0px);
    }
}

@keyframes scrollLeft {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-220px, 0, 0px);
                transform: translate3d(-220px, 0, 0px);
    }
}

@-webkit-keyframes scrollRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(220px, 0, 0px);
                transform: translate3d(220px, 0, 0px);
    }
}

@keyframes scrollRight {
    from {
        opacity: 1;
        -webkit-transform: translate3d(0, 0px, 0px);
                transform: translate3d(0, 0px, 0px);
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(220px, 0, 0px);
                transform: translate3d(220px, 0, 0px);
    }
}

@-webkit-keyframes rollright {
    0% {
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes rollright {
    0% {
        -webkit-transform: translateX(30%);
                transform: translateX(30%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes rollleft {
    0% {
        -webkit-transform: translateX(-30%);
                transform: translateX(-30%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes rollleft {
    0% {
        -webkit-transform: translateX(-30%);
                transform: translateX(-30%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes rollrightMobile {
    0% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes rollrightMobile {
    0% {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes rollleftMobile {
    0% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes rollleftMobile {
    0% {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@media only screen and (max-width: 786px) {
    .homeContainerMain {
        height: 100%;
    }

    .bannerFeatureContainer {
        flex-direction: column-reverse;
        align-items: center;
        height: 100%;
        padding: 0;
    }

    .bannerCenterContent {
        width: 100%;
        max-width: 390px;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 15px;
        gap: 15px;
        padding: 80px 20px 0;
    }

    .bannerCenterContent>p {
        padding: 0 40px;
    }

    h1.startedTitle {
        font-size: 42px;
        text-align: center;
    }

    .startedSubTitle {
        text-align: center;
    }

    .mobileDivison {
        height: 3px;
        width: 80px;
        border-radius: 50px;
        background-color: #D8D9FF;
    }

    .homeContainerMain {
        background: var(--home-bg);
        background: linear-gradient(180deg, var(--home-bg) 0%, #e1d4f4 100%);

    }

    p.startedSubTitle {
        font-size: 24px;
        text-align: center;
    }

    .getStartedLabel {
        font-size: 20px !important;
    }

    .getStartedBtn {
        box-shadow: 2px 4px 30px #586DF780;
        padding: 20px 100px;
        margin: 10px 0;
        border-radius: 45px !important;
    }

    .videoContainer {
        width: 450px;
        padding-left: 20px;
    }

    .brokersWrapper {
        width: 100%;
        background-color: white;
        padding: 45px 20px 0;
    }

    .brokersContainer {
        width: 100%;
        max-width: 500px;
        margin: auto;
        background: #0000FF05 0% 0% no-repeat padding-box;
        padding: 20px;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        grid-gap: 20px;
        gap: 20px
    }

    .brokerText {
        font-size: 13px;
        font-family: nobel-bold;
        color: #4F5783;
        font-weight: 500 !important;
    }

    .brokerImgContainer {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    .bannerBrokerImg {
        width: 100%;
    }

    .broker1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .broker2 {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    .broker3 {
        grid-column: 3 / 4;
        grid-row: 3 / 4;
    }

    .broker4 {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
    }

    .broker5 {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .planContainer {
        padding: 0;
        padding-top: 60px;
    }

    .whyStreakTable {
        width: 100%;
        max-width: 500px;
        background-color: white;
        padding: 0;
        position: relative;
    }

    .WSTableMobileImg {
        width: 100%;
    }

    .ReadMore {
        position: absolute;
        z-index: 4;
        left: 70%;
        top: 90%;
        color: #445DFF;
        font-weight: 800;
        cursor: pointer;
        font-family: multi-display-medium;
    }

    .statItem p {
        font-size: 14px;
    }

    .statBannerContainer {
        background: #2c2047;
        background: radial-gradient(ellipse, #2c2047 -20%, #10142f 50%);
    }

    .infoBannerWrap {
        background-color: #f1f1ff;
        padding: 20px;
        padding-top: 40px;
    }

    .infoContainerMobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .infoRight {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        min-height: 300px;
        margin-bottom: 18px;
    }

    .scrollFromLeft {
        -webkit-animation-name: scrollLeft;
                animation-name: scrollLeft;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }

    .scrollFromRight {
        -webkit-animation-name: scrollRight;
                animation-name: scrollRight;
        animation-direction: reverse;
        -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
    }

    .scrollToLeft {
        -webkit-animation-name: scrollLeft;
                animation-name: scrollLeft;
        animation-direction: reverse;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
    }

    .scrollToRight {
        -webkit-animation-name: scrollRight;
                animation-name: scrollRight;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
    }

    .infoBannerRightButtons {
        bottom: -8%;
    }

    .infoBannerRight {
        width: 100%;
    }

    .infoTextContainerMobile {
        width: 100%;
        max-width: 350px;
        min-height: 250px;
        padding: 20px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .infoTextContainerMobile>p {
        width: 100%;
        margin-bottom: 10px;
        color: var(--home-text-primary);
    }

    .learnMore {
        align-self: flex-start;
        font-size: 16px;
        margin: 0;
    }

    .arrowBtnsContainer {
        display: flex;
        width: 350px;
        justify-content: space-between;
    }

    .left-arrow {
        position: unset;
        width: 40px;
        height: 40px;
        border: 2px solid #000651;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
    }

    .right-arrow {
        position: unset;
        width: 40px;
        height: 40px;
        border: 2px solid #000651;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;
    }

    .left-arrow>img,
    .right-arrow>img {
        width: 50%;
    }

    .statBannerContainer {
        margin: 40px 0;
        width: 90%;
        max-width: 500px;
        min-width: 300px;
        border-radius: 26px;
    }

    .statBannerCenterContent {
        padding: 40px 0;
    }

    .statsContainer {
        width: 100%;
        display: grid;
        grid-template-columns: 47% 47%;
        grid-gap: 20px;
        gap: 20px;
        padding-bottom: 20px;
    }

    .statItem {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        padding: 10px;
        text-align: center;
        background: #1E2139 0% 0% no-repeat padding-box;
    }

    .statItem>p {
        font-size: 14px;
    }

    .flagIcon {
        width: 45px;
    }

    .statGetStartedLabel {
        font-size: 16px !important;
    }

    .statsGetStartedBtn {
        width: 95%;
        border-radius: 16px !important;
        border: none;
    }

    .testimonialFeaturesContainer {
        grid-gap: 5px;
        gap: 5px;
        padding-bottom: 50px;
    }

    .testimonialDisplayMobile {
        width: 100%;
        max-width: 500px;
    }

    .rollToRight {
        -webkit-animation: .5s rollrightMobile normal;
                animation: .5s rollrightMobile normal;
    }

    .rollToLeft {
        -webkit-animation: .5s rollleftMobile normal;
                animation: .5s rollleftMobile normal;
    }


    .testimonialItemMobile {
        width: 90%;
        margin: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        border-radius: 20px;
        min-height: 253px;
    }

    .testimonialUserDetails {
        width: 100%;
        display: flex;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
    }

    .testimonialUserImg {
        width: 15%;
    }

    .testimonialUserName {
        font-size: 14px;
        font-weight: 800 !important;
        font-family: multi-display-medium;
    }

    .comment {
        font-size: 18px;
        padding-bottom: 20px;
    }

    .getStartedContainer {
        padding: 0;
    }

    .getStartedContainer>h1 {
        padding: 60px 60px 0;
    }

    .getStartedLabelFooter {
        font-size: 14px !important;
    }

    .getStartedBtFooter {
        padding: 20px 40px;
    }

    .getStartedBtFooter{
        margin: 0;
        margin-bottom: 10px;
    }

    .getStartedbg {
        width: 200%;
    }

    .sysFooterBottomContent {
        display: grid;
        grid-template-columns: 3fr 1fr;
        margin: auto;
    }

    .sysFooterBottomCol {
        width: 100%;
        text-align: left;
    }

    .sysFooterBottomCol_2 {
        width: 120%;
    }

    .sysFooterSocialContainer {
        width: 190px;
        text-align: left;
        text-align: initial;
        width: 70%;
    }

    .sysFooterTrailSubtitle {
        margin: 16px 0 38px;
    }

    .sysFooterBottomColTitle {
        font-size: 12px !important;
    }

    .sysFooterlink {
        font-size: 12px !important;
    }

    .sysFooterlinkFixed img {
        width: 12px;
    }

    .sysFooter {
        margin: var(--margin-horizontal) 20px 0 24px !important;
        width: calc(100% - 44px) !important;
    }

    .socialsContainer {
        width: 100%;
        grid-column-gap: 32px;
        -webkit-column-gap: 32px;
                column-gap: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .sysFooter {
        padding-bottom: 40px;
    }

    .qoutes {
        width: 10px;
        padding: 5px;
    }
}

@media only screen and (min-width: 786px) {

    /* .bannerFeatureContainer {
        padding-right: 6%;
    } */

    .startedTitle {
        font-size: 28px
    }

    .startedSubTitle {
        font-size: 16px;
    }

    .getStartedLabel {
        font-size: 16px !important;
    }

    .getStartedBtn {
        padding: 15px 45px;
    }

    .bannerBrokerImg {
        width: 40px;
        margin: 10px;
    }

    .bannerCenterContent {
        padding-bottom: 30px;
    }

    .infoBannerWrap .startedSubTitle {
        margin-top: 10px;
        width: 32%;
        font-size: 20px;
    }

    .infoRightButtons {
        bottom: 8%;
    }

    .flagIcon {
        width: 50px;
    }

    .brokerList {
        padding: 10px;
        border-radius: 16px;
        grid-gap: 10px;
        gap: 10px;
    }

    .sysFooterSocialContainer {
        align-items: center;
    }

    .qoutes {
        width: 12px;
        padding: 5px;
    }

    .statBannerCenterContent .startedTitle {
        font-family: multi-display-regular;
    }
}

@media only screen and (min-width: 992px) {

    .startedTitle {
        font-size: 40px
    }

    .startedSubTitle {
        font-size: 20px;
    }

    .infoBannerWrap .startedSubTitle {
        width: 40%;
        font-size: 28px;
    }

    .getStartedLabel {
        font-size: 18px !important;
    }

    .bannerBrokerImg {
        width: 60px;
    }

    .infoRightButtons {
        bottom: -2%;
    }
}

@media only screen and (min-width: 1200px) {

    .bannerFeatureContainer .startedTitle {
        font-size: 55px
    }

    .bannerFeatureContainer .startedSubTitle {
        font-size: 26px;
    }

    .startedTitle {
        font-size: 45px
    }

    .startedSubTitle {
        font-size: 23px;
    }

    .infoBannerWrap .startedSubTitle {
        width: 40%;
        font-size: 34px;
    }

    .getStartedLabel {
        font-size: 20px !important;
    }

    .getStartedBtn {
        padding: 20px 60px;
    }

    .bannerBrokerImg {
        width: 70px;
        margin: 10px;
    }

    .infoRightButtons {
        bottom: -5%;
    }

    .brokerList {
        padding: 14px;
        border-radius: 16px;
        grid-gap: 15px;
        gap: 15px;
    }

    .testimonialContainer {
        min-height: 443px;
        font-size: 27px;
    }

    .statBannerCenterContent .startedTitle {
        font-size: 46px;
    }

    .statBannerCenterContent .startedSubTitle {
        font-size: 35px;
    }

    .statsContainer {
        padding: 30px 0;
    }
}

@media only screen and (min-width: 1600px) {

    .startedTitle {
        font-size: 60px
    }

    .startedSubTitle {
        font-size: 30px;
    }

    .infoBannerWrap .startedSubTitle {
        width: 40%;
        font-size: 42px;
    }

    .infoBannerLeftLabel {
        font-size: 26px;
    }

    .featuresDesc {
        font-size: 24px;
        padding-right: 40px !important;
    }

    .featureButtons svg {
        width: 40px;
        height: 30px;
    }

    .featureButtonsActive svg {
        width: 40px;
        height: 30px;
    }

    .learnMore {
        font-size: 24px;
    }

    .getStartedLabel {
        font-size: 18px !important;
    }

    .bannerBrokerImg {
        width: 60px;
        margin: 15px;
    }

    .brokerList {
        padding: 20px;
        border-radius: 16px;
        grid-gap: 20px;
        gap: 20px;
    }

}

@media only screen and (min-width: 2000px) {

    .systematicTradingContainer {
        --home-small: 18px;
        --home-text: 22px;
        --home-text-15: 22px;
        --home-text-22: 30px;
        --home-large: 42px;
        --home-large-1: 50px;
        --home-xlarge: 60px;
        --margin-horizontal: 100px;
    }


    .bannerFeatureContainer {
        padding-right: 10%;
    }

    .startedSubTitle {
        font-size: 32px;
    }

    .infoRightText {
        font-size: 26px !important;
    }

    .infoRight {
        min-height: 500px;
    }

    .infoRightImg {
        width: 200px !important;
    }

    .infoFeaturesTitle {
        margin-bottom: 50px;
    }

}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  --anim-func-bouncy: cubic-bezier(0.15, 0.47, 0, 1.16);
  --anim-func-ease: cubic-bezier(0, 0.62, 0.24, 1.04);
}

body::-webkit-scrollbar {
  display: none;
}

[data-tour="tour"] {
  overflow-y: hidden !important;
}

[data-tour="tour"] [data-value="sticky"] {
  position: inherit !important;
  z-index: unset;
}

[data-tour="tour"] [data-value="tour"] {
  z-index: 9999 !important;
  position: relative;
  border-radius: 5px;
  background-color: var(--screenBg);
}

[data-tour="tour"] [data-value="tour-highlighted"] {
  z-index: 9999 !important;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  border: 4px solid #E9ECFF;
  background-color: var(--screenBg);
}

div {
  box-sizing: border-box;
}

ul,
h1,
p,
h5 {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

input,
textarea {
  border-style: none;
  border-width: 0;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: var(--text);
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholderDarkColor);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholderDarkColor);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  opacity: 1;
  /* Firefox */
}

input::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholderDarkColor);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholderDarkColor);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--placeholderDarkColor);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

input:focus {
  outline: none;
}

#root {
  overflow: hidden;
}

p {
  margin: 0;
}

a {
  color: var(--linkColor);
  text-decoration: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  box-sizing: border-box;
  font: 400 14px "Poppins", sans-serif;
  border: 0;
  padding: 0;
}

button:focus {
  outline: none;
}

button:disabled {
  cursor: default;
}

table {
  border: 0;
  border-spacing: 0px;
}

img {
  font-size: 10px;
}

th {
  padding: 0;
}

.free {
  background: linear-gradient(91deg, #00B041, #2D64FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/** xxxxxxxxx-------xxxxxx------xxxxx----common css end------xxxxx-------xxxxxxxx */

/*****************  marquee styles */
@-webkit-keyframes marque_slide_left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@keyframes marque_slide_left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

/**xxxxxxxxx-xxxx----- marquee styles----xxxxxx----------------xxxxxxxxxxxxx */

/* new product tour styling  */

#tour_scan_1 {
  padding: 0 !important;
}

#tour_deploy_2_1 {
  margin-top: 90px;
}

#tour-dot {
  position: fixed;
  opacity: 0;
  width: 38px;
  height: 38px;
  z-index: 9999999;
  pointer-events: none;
}

@media only screen and (min-width:1024px) {
  #tour-popper-deploy {
    left: 1040px !important;
  }
}

#tour_tech_5 {
  top: 100px;
}

#tour_deploy_4,
#tour_deploy_5,
#tour_deploy_6,
#tour_tech_1,
#tour_create_strategy_4,
#tour_create_strategy_5,
#tour_scan_create_2,
#tour_create_strategy_2,
#tour_tech_create_2,
#tour_tech_create_3,
#tour_tech_create_4,
#tour_scan_3,
#tour_scan_4 {
  border: none !important;
}

#tour_tech_id,
#tour_backtest_2_dup {
  border: 4px solid #E9ECFF;
}

#tour_backtest_2_dup {
  border-radius: 11px;
}

.tour-dot-deploy {
  left: 650px !important;
  top: 455px !important;
}

.nav-arrow {
  position: fixed;
  border-style: solid;
  top: -1000px;
  z-index: 999999;
  left: calc(50% - 30px);
  border-width: 0px 30px 30px 30px;
  border-color: transparent transparent var(--productTourBg);
}

.tour-arrow {
  position: absolute;
  border-style: solid;
}

.tour-popper {
  transition: all 0.01s ease-out;
}

.tour-popper[data-placement^="top"]>.tour-arrow {
  bottom: -9px;
  border-width: 10px 10px 0px 10px;
  border-color: var(--bgPrimary) transparent transparent;
}

.tour-popper[data-placement^="bottom"]>.tour-arrow {
  top: -9px;
  border-width: 0px 10px 10px 10px;
  border-color: transparent transparent var(--productTourBg);
}

.tour-popper[data-placement^="right"]>.tour-arrow {
  left: -9px;
  border-width: 10px 10px 10px 0px;
  border-color: transparent var(--productTourBg) transparent transparent;
}

.tour-popper[data-placement^="left"]>.tour-arrow {
  right: -9px;
  border-width: 10px 0px 10px 10px;
  border-color: transparent transparent transparent var(--productTourBg);
}

.optionsText {
  position: absolute;
  font-size: 7px;
  color: #fff;
  width: 0.85rem;
  border-radius: 20px !important;
  margin-left: 5px !important;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

@media only screen and (max-width:1024px) {
  .tour-popper[data-placement^="top"]>.tour-arrow {
    bottom: -9px;
    border-width: 10px 10px 0px 10px;
    border-color: var(--bgPrimary) transparent transparent;
  }

  .tour-popper[data-placement^="bottom"]>.tour-arrow {
    top: -9px;
    border-width: 0px 10px 10px 10px;
    border-color: transparent transparent var(--productTourBg);
  }
}

@media only screen and (min-width: 1024px) {
  .tour-popper[data-placement^="top"]>.tour-arrow {
    left: calc(50% - 10px) !important;
  }

  .tour-popper[data-placement^="bottom"]>.tour-arrow {
    left: calc(50% - 10px) !important;
  }
}

/* *********************** new product tour styling ends ********************************** */

/*****************  marquee styles */

/**xxxxxxxxx-xxxx----- marquee styles----xxxxxx----------------xxxxxxxxxxxxx */

.tippy-content {
  z-index: 9;
}

.tippy-arrow,
.tippy-arrow::before {
  position: absolute;
  width: 20px;
  height: 14px;
  background-color: inherit;
  /* z-index: -9; */
}

.tippy-arrow {
  visibility: hidden;
  z-index: -1;
}

.tippy-arrow::before {
  visibility: visible;
  content: "";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.tippy-box[data-placement^="top"]>.tippy-arrow {
  bottom: -4px;
}

.tippy-box[data-placement^="bottom"]>.tippy-arrow {
  top: -4px;
}

.tippy-box[data-placement^="left"]>.tippy-arrow {
  right: -6px;
}

.tippy-box[data-placement^="right"]>.tippy-arrow {
  left: -6px;
}

/******** date time picker ******/
.rdtPicker {
  border-color: var(--borderColor);
  background-color: var(--dropdownBg);
}

.rdtPicker th,
td {
  color: var(--text);
}

.rdtPicker th:hover {
  background-color: var(--hover) !important;
}

.rdtPicker td:hover {
  background-color: var(--hover) !important;
}

.rdtCounter>.rdtBtn:hover {
  background-color: var(--hover);
}

.rdtMonths>table {
  /* display: inline-block; */
}

.rdtMonths>table>tbody {
  /* display: inherit; */
  /* width: 100%; */
  /* font-size: 10px;
    font-weight: bold; */
}

.rdtMonths>table>tbody>tr {
  /* display: block; */
}

/* xxxxxxxxxx------ date time picker ends ---xxxxxx-----------xxxxx--- */

/* switch css */
.cSwitchLabel {
  display: flex;
  margin: 0 0 0 10px;
}

.cSwitchSlider {
  background: white;
  border-radius: 10px;
  width: 32px;
  position: relative;
  display: inline-block;
  height: 18px;
  cursor: pointer;
  transition: 0.4s;
  background-color: var(--switchTrack-false);
}

.cSwitchSlider::before {
  background-color: var(--switchThumb-false);
  height: 16px;
  width: 16px;
  content: " ";
  position: absolute;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  cursor: pointer;
}

.cSwitchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.cSwitchInput:checked+.cSwitchSlider::before {
  background-color: var(--switchThumb-true);
  -webkit-transform: translateX(14px);
          transform: translateX(14px);
}

.cSwitchInput:checked+.cSwitchSlider {
  background-color: var(--switchTrack-true);
}

/* switch css end --------xxxx--------xxxx */

/* BacktestResultsCard css animation **********/
@-webkit-keyframes next_gain {
  0% {
    background-image: linear-gradient(to right, #fff 14%, var(--gainBg) 20%);
  }

  30% {
    background-image: linear-gradient(to right, #fff 10%, var(--gainBg) 15%);
  }

  60% {
    background-image: linear-gradient(to right, #fff 6%, var(--gainBg) 10%);
  }

  90% {
    background-image: linear-gradient(to right, #fff 2%, var(--gainBg) 5%);
  }

  100% {
    background-image: linear-gradient(to right, #fff 0%, var(--gainBg) 0%);
  }
}
@keyframes next_gain {
  0% {
    background-image: linear-gradient(to right, #fff 14%, var(--gainBg) 20%);
  }

  30% {
    background-image: linear-gradient(to right, #fff 10%, var(--gainBg) 15%);
  }

  60% {
    background-image: linear-gradient(to right, #fff 6%, var(--gainBg) 10%);
  }

  90% {
    background-image: linear-gradient(to right, #fff 2%, var(--gainBg) 5%);
  }

  100% {
    background-image: linear-gradient(to right, #fff 0%, var(--gainBg) 0%);
  }
}

@-webkit-keyframes back_gain {
  0% {
    background-image: linear-gradient(to left, #fff 14%, var(--gainBg) 20%);
  }

  30% {
    background-image: linear-gradient(to left, #fff 10%, var(--gainBg) 15%);
  }

  60% {
    background-image: linear-gradient(to left, #fff 6%, var(--gainBg) 10%);
  }

  90% {
    background-image: linear-gradient(to left, #fff 2%, var(--gainBg) 5%);
  }

  100% {
    background-image: linear-gradient(to left, #fff 0%, var(--gainBg) 0%);
  }
}

@keyframes back_gain {
  0% {
    background-image: linear-gradient(to left, #fff 14%, var(--gainBg) 20%);
  }

  30% {
    background-image: linear-gradient(to left, #fff 10%, var(--gainBg) 15%);
  }

  60% {
    background-image: linear-gradient(to left, #fff 6%, var(--gainBg) 10%);
  }

  90% {
    background-image: linear-gradient(to left, #fff 2%, var(--gainBg) 5%);
  }

  100% {
    background-image: linear-gradient(to left, #fff 0%, var(--gainBg) 0%);
  }
}

@-webkit-keyframes next_loss {
  0% {
    background-image: linear-gradient(to right, #fff 14%, var(--lossBg) 20%);
  }

  30% {
    background-image: linear-gradient(to right, #fff 10%, var(--lossBg) 15%);
  }

  60% {
    background-image: linear-gradient(to right, #fff 6%, var(--lossBg) 10%);
  }

  90% {
    background-image: linear-gradient(to right, #fff 2%, var(--lossBg) 5%);
  }

  100% {
    background-image: linear-gradient(to right, #fff 0%, var(--lossBg) 0%);
  }
}

@keyframes next_loss {
  0% {
    background-image: linear-gradient(to right, #fff 14%, var(--lossBg) 20%);
  }

  30% {
    background-image: linear-gradient(to right, #fff 10%, var(--lossBg) 15%);
  }

  60% {
    background-image: linear-gradient(to right, #fff 6%, var(--lossBg) 10%);
  }

  90% {
    background-image: linear-gradient(to right, #fff 2%, var(--lossBg) 5%);
  }

  100% {
    background-image: linear-gradient(to right, #fff 0%, var(--lossBg) 0%);
  }
}

@-webkit-keyframes back_loss {
  0% {
    background-image: linear-gradient(to left, #fff 14%, var(--lossBg) 20%);
  }

  30% {
    background-image: linear-gradient(to left, #fff 10%, var(--lossBg) 15%);
  }

  60% {
    background-image: linear-gradient(to left, #fff 6%, var(--lossBg) 10%);
  }

  90% {
    background-image: linear-gradient(to left, #fff 2%, var(--lossBg) 5%);
  }

  100% {
    background-image: linear-gradient(to left, #fff 0%, var(--lossBg) 0%);
  }
}

@keyframes back_loss {
  0% {
    background-image: linear-gradient(to left, #fff 14%, var(--lossBg) 20%);
  }

  30% {
    background-image: linear-gradient(to left, #fff 10%, var(--lossBg) 15%);
  }

  60% {
    background-image: linear-gradient(to left, #fff 6%, var(--lossBg) 10%);
  }

  90% {
    background-image: linear-gradient(to left, #fff 2%, var(--lossBg) 5%);
  }

  100% {
    background-image: linear-gradient(to left, #fff 0%, var(--lossBg) 0%);
  }
}

.backtest-anim {
  /* animation-duration: 80ms; */
  -webkit-animation-timing-function: cubic-bezier(0, 0.62, 0.24, 1.04);
          animation-timing-function: cubic-bezier(0, 0.62, 0.24, 1.04);
  /* animation-delay: 250ms; */
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes next_loss_gain {
  from {
    background-image: linear-gradient(to right,
        var(--lossBg) 20%,
        var(--gainBg) 100%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--lossBg) 0%,
        var(--gainBg) 0%);
  }
}

@keyframes next_loss_gain {
  from {
    background-image: linear-gradient(to right,
        var(--lossBg) 20%,
        var(--gainBg) 100%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--lossBg) 0%,
        var(--gainBg) 0%);
  }
}

@-webkit-keyframes next_gain_loss {
  from {
    background-image: linear-gradient(to right,
        var(--gainBg) 20%,
        var(--lossBg) 100%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--gainBg) 0%,
        var(--lossBg) 0%);
  }
}

@keyframes next_gain_loss {
  from {
    background-image: linear-gradient(to right,
        var(--gainBg) 20%,
        var(--lossBg) 100%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--gainBg) 0%,
        var(--lossBg) 0%);
  }
}

@-webkit-keyframes next_gain_gain {
  from {
    background-image: linear-gradient(to right,
        var(--gainBg) 14%,
        var(--gainBg) 20%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--gainBg) 0%,
        var(--gainBg) 0%);
  }
}

@keyframes next_gain_gain {
  from {
    background-image: linear-gradient(to right,
        var(--gainBg) 14%,
        var(--gainBg) 20%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--gainBg) 0%,
        var(--gainBg) 0%);
  }
}

@-webkit-keyframes next_loss_loss {
  from {
    background-image: linear-gradient(to right,
        var(--lossBg) 14%,
        var(--lossBg) 20%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--lossBg) 0%,
        var(--lossBg) 0%);
  }
}

@keyframes next_loss_loss {
  from {
    background-image: linear-gradient(to right,
        var(--lossBg) 14%,
        var(--lossBg) 20%);
  }

  to {
    background-image: linear-gradient(to right,
        var(--lossBg) 0%,
        var(--lossBg) 0%);
  }
}

@-webkit-keyframes back_loss_gain {
  from {
    background-image: linear-gradient(to left,
        var(--lossBg) 20%,
        var(--gainBg) 100%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--lossBg) 0%,
        var(--gainBg) 0%);
  }
}

@keyframes back_loss_gain {
  from {
    background-image: linear-gradient(to left,
        var(--lossBg) 20%,
        var(--gainBg) 100%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--lossBg) 0%,
        var(--gainBg) 0%);
  }
}

@-webkit-keyframes back_gain_loss {
  from {
    background-image: linear-gradient(to left,
        var(--gainBg) 20%,
        var(--lossBg) 100%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--gainBg) 0%,
        var(--lossBg) 0%);
  }
}

@keyframes back_gain_loss {
  from {
    background-image: linear-gradient(to left,
        var(--gainBg) 20%,
        var(--lossBg) 100%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--gainBg) 0%,
        var(--lossBg) 0%);
  }
}

@-webkit-keyframes back_gain_gain {
  from {
    background-image: linear-gradient(to left,
        var(--gainBg) 14%,
        var(--gainBg) 20%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--gainBg) 0%,
        var(--gainBg) 0%);
  }
}

@keyframes back_gain_gain {
  from {
    background-image: linear-gradient(to left,
        var(--gainBg) 14%,
        var(--gainBg) 20%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--gainBg) 0%,
        var(--gainBg) 0%);
  }
}

@-webkit-keyframes back_loss_loss {
  from {
    background-image: linear-gradient(to left,
        var(--lossBg) 14%,
        var(--lossBg) 20%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--lossBg) 0%,
        var(--lossBg) 0%);
  }
}

@keyframes back_loss_loss {
  from {
    background-image: linear-gradient(to left,
        var(--lossBg) 14%,
        var(--lossBg) 20%);
  }

  to {
    background-image: linear-gradient(to left,
        var(--lossBg) 0%,
        var(--lossBg) 0%);
  }
}

.backtest-details-anim {
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
  -webkit-animation-timing-function: cubic-bezier(0, 0.62, 0.24, 1.04);
          animation-timing-function: cubic-bezier(0, 0.62, 0.24, 1.04);
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

/* -----xxxxx-----xxxxxx--------BacktestResultsCard css animation end-----xxxxxxxxx *****/

/* -----xxxxx-----xxxxxx--------modal css animation-----xxxxxxxxx *****/

.modal-jump {
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
  -webkit-animation-timing-function: cubic-bezier(0.15, 0.47, 0, 1.16);
          animation-timing-function: cubic-bezier(0.15, 0.47, 0, 1.16);
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes modalAnimJumpIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7) translate3d(0, -20px, -40px);
            transform: scale(0.7) translate3d(0, -20px, -40px);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes modalAnimJumpIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.7) translate3d(0, -20px, -40px);
            transform: scale(0.7) translate3d(0, -20px, -40px);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }
}

@-webkit-keyframes modalAnimJumpOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 20px, 40px);
            transform: scale(1) translate3d(0, 20px, 40px);
  }
}

@keyframes modalAnimJumpOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(1) translate3d(0, 20px, 40px);
            transform: scale(1) translate3d(0, 20px, 40px);
  }
}

.modal-slide {
  -webkit-animation-duration: 450ms;
          animation-duration: 450ms;
  -webkit-animation-timing-function: cubic-bezier(0.42, -0.05, 0.01, 0.96);
          animation-timing-function: cubic-bezier(0.42, -0.05, 0.01, 0.96);
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes modalAnimSlide_right {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes modalAnimSlide_right {
  from {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes modalAnimSlide_right_reverse {
  from {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  to {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes modalAnimSlide_right_reverse {
  from {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  to {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@-webkit-keyframes modalAnimSlide_bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes modalAnimSlide_bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@-webkit-keyframes modalAnimSlide_bottom_reverse {
  from {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
  }
}

@keyframes modalAnimSlide_bottom_reverse {
  from {
    opacity: 1;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
  }
}

/* -----xxxxx-----xxxxxx--------modal css animation end-----xxxxxxxxx *****/

/* -----xxxxx-----xxxxxx--------checkbox css-----xxxxxxxxx *****/

.cCheckboxLabel {
  display: flex;
  cursor: pointer;
  transition: 0.4s;
}

.cCheckboxLabel:hover>.cCheckboxTick {
  border-color: var(--linkColor);
}

.cCheckboxTick {
  position: relative;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid var(--checkboxBorder);
}

.cCheckboxTick::before {
  content: "";
  position: absolute;
  left: 4px;
  top: 1.5px;
  height: 7px;
  width: 4px;
  opacity: 0;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cCheckboxRound .cCheckboxTick::before {
  left: 7px;
  top: 4px;
  height: 7px;
  width: 3px;
}

.cCheckboxRound .cCheckboxTick {
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.cCheckboxInput:checked+.cCheckboxTick::before {
  opacity: 1;
}

.cCheckboxInput:checked+.cCheckboxTick {
  background-color: var(--linkColor);
  border-color: var(--linkColor);
}

.cCheckboxInput {
  opacity: 0;
  width: 0;
  height: 0;
}

/* -----xxxxx-----xxxxxx--------checkbox css ends-----xxxxxxxxx *****/

/* -----xxxxx-----xxxxxx--------radio css start-----xxxxxxxxx *****/

.radiogroup {
  display: flex;
  align-items: center;
}

.cRadioLabel {
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: 0.4s;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 12px;
}

.cRadioInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.cRadioTick {
  position: relative;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border-radius: 50%;
  border: 2.5px solid var(--checkboxBorder);
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cRadioLabel .cRadioTick::after {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  align-self: center;
}

.cRadioLabel_blue .cRadioTick::after {
  background-color: var(--linkColor);
}

.cRadioLabel_openblue .cRadioTick::after {
  background-color: var(--linkBlue);
}

.cRadioLabel_openorange .cRadioTick::after {
  background-color: var(--sell);
}

.cRadioLabel_orange .cRadioTick::after {
  background-color: var(--sell);
}

.cRadioLabel:hover input~.cRadioTick {
  border-color: #ccc;
}

.cRadioLabel_blue .cRadioInput:checked+.cRadioTick {
  border-color: var(--linkColor);
}

.cRadioLabel_openblue .cRadioInput:checked+.cRadioTick {
  border-color: var(--linkBlue);
}


.cRadioLabel_openorange .cRadioInput:checked+.cRadioTick {
  border-color: var(--sell);
}

.cRadioLabel_orange .cRadioInput:checked+.cRadioTick {
  border-color: var(--sell);
}

.cRadioInput:disabled+.cRadioTick {
  border-color: #afafaf !important;
}

.cRadioInput:disabled+.cRadioTick::after {
  background-color: #afafaf !important;
}

.cRadioInput:disabled~p {
  color: #afafaf !important;
}

.cRadioTick::after {
  content: "";
  position: absolute;
  display: none;
}

.cRadioLabel input:checked~.cRadioTick::after {
  display: block;
}

/* -----xxxxx-----xxxxxx--------radio css ends-----xxxxxxxxx *****/

/* -----xxxxx-----xxxxxx--------deploy modal css-----xxxxxxxxx *****/
.deploy-modal {
  max-width: 700px !important;
  height: 80%;
  max-height: 460px !important;
  padding: 0 !important;
  overflow: hidden;
  margin: 50px auto !important;
}

/* -----xxxxx-----xxxxxx--------deploy modal css end-----xxxxxxxxx *****/

.snackbar-container {
  position: fixed;
  bottom: 20px;
  z-index: 999999;
  left: 20px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 100vh;
  max-width: 380px;
}

.buy-sell-modal {
  padding: 0 !important;
  width: unset !important;
  max-width: unset !important;
  border-radius: 4px !important;
}

.toggle-theme-anim * {
  transition: all 0.8s var(--anim-func-ease);
  -webkit-transition: all 0.8s var(--anim-func-ease);
}

.animation-disabled * {
  transition: none !important;
  -webkit-transition: none !important;
}

@-webkit-keyframes hide-on-scroll {
  from {
    /* padding-top: 20px; */
    bottom: 0;
    opacity: 1;
  }

  to {
    /* padding-top: 0; */
    bottom: 10px;
    opacity: 0;
  }
}

@keyframes hide-on-scroll {
  from {
    /* padding-top: 20px; */
    bottom: 0;
    opacity: 1;
  }

  to {
    /* padding-top: 0; */
    bottom: 10px;
    opacity: 0;
  }
}

@-webkit-keyframes show-on-scroll {
  from {
    /* padding-top: 0; */
    bottom: 10px;
    opacity: 0;
  }

  to {
    /* padding-top: 20px; */
    bottom: 0;
    opacity: 1;
  }
}

@keyframes show-on-scroll {
  from {
    /* padding-top: 0; */
    bottom: 10px;
    opacity: 0;
  }

  to {
    /* padding-top: 20px; */
    bottom: 0;
    opacity: 1;
  }
}

#authScreeens,
#homeScreens .homeWrapper>div,
#headerContainer {
  max-width: 1600px;
  margin: auto;
}

#logoSection {
  margin-left: 0;
}

@media only screen and (min-width: 1440px) and (max-width: 1900px) {

  #authScreeens,
  #homeScreens .homeWrapper>div,
  #headerContainer {
    width: 80%;
    margin: auto;
  }

  #logoSection {
    margin-left: -9%;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {

  #authScreeens,
  #homeScreens .homeWrapper>div,
  #headerContainer {
    width: 85%;
    margin: auto;
  }

  #logoSection {
    margin-left: -7.5%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1366px) {

  #authScreeens,
  #homeScreens .homeWrapper>div,
  #headerContainer {
    width: 93%;
    margin: auto;
  }

  #logoSection {
    margin-left: 0;
  }
}

@media only screen and (min-width: 787px) and (max-width: 1024px) {

  #authScreeens,
  #homeScreens .homeWrapper>div,
  #headerContainer {
    width: 95%;
    margin: auto;
  }

  #logoSection {
    margin-left: 0;
  }
}

@media only screen and (max-width: 786px) {

  #authScreeens,
  #headerContainer {
    width: unset;
    margin: 0 20px 54px;
  }

  #logoSection {
    margin-left: 0;
  }

  .deploy-modal {
    height: 100%;
    max-height: unset !important;
    overflow: hidden;
    margin: 0px auto !important;
  }
}

@media only screen and (max-width: 425px) {
  .labelInputContainer {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.tooltip-arrow,
.tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow::before {
  visibility: visible;
  content: "";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.main-tooltip[data-popper-placement^="top"]>.tooltip-arrow {
  bottom: -4px;
}

.main-tooltip[data-popper-placement^="bottom"]>.tooltip-arrow {
  top: -4px;
}

.main-tooltip[data-popper-placement^="left"]>.tooltip-arrow {
  right: -4px;
}

.main-tooltip[data-popper-placement^="right"]>.tooltip-arrow {
  left: -4px;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* nudge stylig for table*/
#undefined_nudge {
  width: 0px !important;
}

/* open screener direct order */
.directOrder {
  width: 100vw !important;
  height: 92vh !important;
  border: none;
}
